import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmailIcon from "@mui/icons-material/Email";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function JoinWaitlist({ onClick }: { onClick?: () => void }) {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{
        scale: 0.9,
        rotate: [0, -10, 10, -10, 10, 0],
        transition: { duration: 0.4 },
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          border: "0.8px solid #868686",
          color: "#1212FF",
          paddingTop: "12px",
          paddingBottom: "12px",
          paddingLeft: "24px",
          paddingRight: "24px",
          textTransform: "none",
          fontSize: 24,
          fontFamily: "Inter Tight",
          fontWeight: 600,
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        endIcon={<ArrowForwardIcon />}
        disableRipple
        disableElevation
        disableFocusRipple
        disableTouchRipple
        onClick={onClick}
      >
        Join waitlist
      </Button>
    </motion.div>
  );
}

function App() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [heroTextIndex, setHeroTextIndex] = React.useState<number>(0);
  const [waitlistOpen, setWaitlistOpen] = React.useState<boolean>(false);
  const [waitlistLoading, setWaitlistLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");

  const heroTexts = [
    "Social wallets that work for you.",
    "The future of money.",
    "Secure, private, and easy to use.",
  ];

  const ctrls = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView, heroTextIndex]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setHeroTextIndex((prev) => {
        if (prev === heroTexts.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const handleWaitlistOpen = () => {
    setWaitlistOpen(true);
  };

  async function submitWaitlist() {
    setWaitlistLoading(true);

    fetch("/waitlist?email=" + email, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          setWaitlistOpen(false);
          setWaitlistLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setWaitlistLoading(false);
        setWaitlistOpen(false);
      });
  }

  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/background.png"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          minHeight: "50vh",
          width: "100vw",
          display: "flex",
        }}
      >
        <Container
          sx={{
            mt: 4,
            mb: 24,
          }}
        >
          <Grid container justifyContent="right" sx={{ mb: 16 }}>
            <JoinWaitlist onClick={handleWaitlistOpen} />
          </Grid>
          <AnimatePresence
            key={heroTextIndex}
            onExitComplete={() => setHeroTextIndex((prev) => prev + 1)}
          >
            <Typography
              sx={{
                color: "#1212FF",
                fontSize: 48,
                fontWeight: 900,
                textAlign: "center",
                fontFamily: "Inter Tight",
              }}
              component="h1"
            >
              {heroTexts[heroTextIndex].split(" ").map((word, index) => (
                <motion.span
                  ref={ref}
                  key={index}
                  variants={{
                    hidden: {},
                    visible: {},
                  }}
                  transition={{
                    delayChildren: index * 0.25,
                    staggerChildren: 0.05,
                  }}
                  initial="hidden"
                  animate={ctrls}
                  style={{
                    display: "inline-block",
                    marginRight: "0.25em",
                    whiteSpace: "nowrap",
                  }}
                >
                  {word.split("").map((word, index) => (
                    <motion.span
                      key={index}
                      variants={{
                        hidden: {
                          opacity: 0,
                          y: `0.25em`,
                        },
                        visible: {
                          opacity: 1,
                          y: `0em`,
                          transition: {
                            duration: 1,
                            ease: [0.2, 0.65, 0.3, 0.9],
                          },
                        },
                      }}
                      style={{
                        display: "inline-block",
                        marginRight: "-0.02em",
                      }}
                    >
                      {word}
                      {index !== heroTexts[heroTextIndex].split(" ").length - 1
                        ? " "
                        : ""}
                    </motion.span>
                  ))}
                </motion.span>
              ))}
            </Typography>
          </AnimatePresence>
        </Container>
      </Box>
      <br />
      <br />
      <Container>
        <Grid container justifyContent="center" alignItems="center" spacing={6}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#4949FF",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.2)",
                  fontSize: isMobile ? "6vw" : "2.4vw",
                  fontWeight: 900,
                  fontFamily: "Inter Tight",
                }}
              >
                Transaction History • Easy Backups • Portfolio • Fraud Detection
                • Purchase Crypto • Connect Friends • Connect Exchanges • Swap
                Assets • Explore NFTs • Hide Tokens • Duress Passwords •
                Available Everywhere • Blazingly Fast
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                color: "#3737FF",
                fontSize: 42,
                fontWeight: 900,
                fontFamily: "Inter Tight",
                mb: 2,
              }}
            >
              For you.
            </Typography>
            <Typography
              sx={{
                color: "#3737FF",
                fontSize: 24,
                fontWeight: 500,
                fontFamily: "Inter",
              }}
            >
              Whether you are an expert or a newbie, we offer the same user
              experience. Get started within seconds and connect with your
              friends using Monye.
              <br />
              <br />
              You're in control and we're your flight attendants on this
              journey.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container maxWidth="md">
        <Typography
          variant="h5"
          sx={{
            color: "#3737FF",
            fontSize: 42,
            fontWeight: 900,
            fontFamily: "Inter Tight",
            mb: 2,
          }}
          textAlign="center"
        >
          Unique.
        </Typography>
        <Grid container justifyContent="center">
          <Typography
            sx={{
              color: "#3737FF",
              fontSize: 24,
              fontWeight: 500,
              fontFamily: "Inter",
              textAlign: "center",
              maxWidth: "640px",
              mb: 4,
            }}
          >
            Monye is a cryptocurrency wallet that offers a unique user
            experience. With Monye, you can manage your digital assets with ease
            and security. Our platform is designed to be user-friendly, making
            it easy for anyone to use, regardless of their technical expertise.
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Box
            sx={{
              backgroundColor: "#3737FF",
              width: "3px",
              height: "128px",
              margin: "0 16px",
              mb: 4,
            }}
          ></Box>
        </Grid>
        <Grid container justifyContent="center">
          <JoinWaitlist onClick={handleWaitlistOpen} />
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <Box
        sx={{
          backgroundColor: "#DBDBFF",
          minHeight: "128px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container>
          <Stack
            direction={isMobile ? "column-reverse" : "row"}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                color: "#4949FF",
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Inter Tight",
              }}
            >
              Arriving 2023.
            </Typography>
            <Stack direction="column" spacing={1.5}>
              <Typography
                sx={{
                  color: "#2424FF",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Inter Tight",
                  letterSpacing: "0.2em",
                }}
              >
                BUILT AROUND THE GLOBE 🌎
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Zeyada",
                  fontSize: 24,
                  color: "#0000FF",
                  textAlign: isMobile ? "center" : undefined,
                }}
              >
                Monye
              </Typography>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Dialog
        open={waitlistOpen}
        onClose={() => setWaitlistOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            background: "#EDEDFF",
            width: "100%",
            maxWidth: "600px",
            borderRadius: "8px",
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
        transitionDuration={0}
      >
        <DialogContent>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 900,
              fontFamily: "Inter Tight",
              color: "#4949FF",
            }}
          >
            Join the waitlist
          </Typography>
          <Typography
            sx={{
              color: "#4949FF",
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "Inter",
              mb: 4,
            }}
          >
            We're currently in private beta. Join the waitlist to be the first
            to know when we launch. We will prioritize our waitlist members for
            early access.
          </Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              mb: 4,
              input: {
                color: "#4949FF",
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Inter",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#4949FF",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#4949FF",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#4949FF",
              },

              "& .MuiFormHelperText-root": {
                color: "#4949FF",
              },

              "& .MuiInputAdornment-root": {
                color: "#4949FF",
              },

              "& .MuiInputLabel-root": {
                color: "#4949FF",
                fontWeight: 500,
                fontFamily: "Inter",
              },
            }}
            helperText="We'll never share your email with anyone else."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            type="email"
          />
          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.95 }}
            style={{ width: "100%" }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4949FF",
                color: "#FFFFFF",
                fontWeight: 500,
                fontFamily: "Inter",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#4949FF",
                },
                textTransform: "none",
                boxShadow: "none",
              }}
              endIcon={<ArrowForwardIcon />}
              disableElevation
              disableRipple
              disableFocusRipple
              onClick={submitWaitlist}
              disabled={waitlistLoading}
              startIcon={
                waitlistLoading ? (
                  <CircularProgress size={20}></CircularProgress>
                ) : null
              }
            >
              {waitlistLoading ? "Loading..." : "Submit"}
            </Button>
          </motion.div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default App;
